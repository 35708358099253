<template>
  <div>
    <h2 class="text-blue text-left text-lg font-bold mb-6">Pasos</h2>
    <div class="relative">
      <a-button
        class="btn-save-temporaly"
        shape="round"
        type="primary"
        @click="saveTemporaly()"
        >GUARDAR COMO BORRADOR</a-button
      >
      <div class="w-full pr-14 my-8">
        <a-steps v-model="actualStep" class="w-full">
          <a-step v-for="item in steps" :key="item.title" />
        </a-steps>
      </div>

      <transition name="slide-fade" mode="out-in" appear>
        <Step1
            v-if="actualStep === 0"
            :saveSignal="saveSignal"
            @on-step-error="onErrorForm"
        />
        <Step2
          v-if="actualStep === 1"
          :saveSignal="saveSignal"
          @on-step-error="onErrorForm"
        />
        <Step3
          v-if="actualStep === 2"
          :saveSignal="saveSignal"
          @on-step-error="onErrorForm"
        />
        <Step4
          v-if="actualStep === 3"
          :saveSignal="saveSignal"
          @on-step-error="onErrorForm"
        />
        <Step5
          :finishing="finishing"
          :saveSignal="saveSignal"
          @on-step-error="onErrorForm"
          v-if="actualStep === 4"
        />
      </transition>
    </div>

    <div class="my-8">
      <h6 v-if="actualStep !== 5" class="my-5 font-bold text-gray-500">
        Paso <span class="text-blue">{{ actualStep + 1 }}</span> de
        <span class="text-blue"> 5</span>
      </h6>
      <a-button
        class="mx-3"
        type="primary"
        ghost
        shape="round"
        @click="previous"
      >
        VOlVER</a-button
      >
      <a-button
        class="mx-3"
        type="primary"
        shape="round"
        v-if="actualStep != 4"
        @click="next"
      >
        SIGUIENTE</a-button
      >
      <a-button type="primary" shape="round" v-else @click="endReport">
        FINALIZAR INFORME</a-button
      >
    </div>
    <Modal v-if="showModalEndReport" size="2xl" class="text-center">
      <h1 class="text-blue-dark text-center text-2xl font-bold mt-4 mb-2">
        Finalizar registro de informe
      </h1>
      <div class="relative">
        <span
          class="text-2xl bottom-5 absolute right-0 hover:text-gray-500"
          @click="closeModal"
          ><a-icon type="close"></a-icon>
        </span>
      </div>
      <p>
        ¿Está seguro de que se ha diligenciado de manera satisfactoria el
        informe final suministrado?
      </p>
      <p>
        <b>RECUERDE:</b> Una vez de por aceptado el registro de este informe, no
        se podrá hacer ninguna modificación de este.
      </p>

      <div class="flex gap-4 justify-center items-center my-8 mx-8">
        <BaseDate label="Fecha del informe" v-model="report_date" disabled />
        <BaseInput label="Entidad" v-model="report_company" disabled />
      </div>
      <div class="flex gap-3 justify-center my-5 w-2/3 mx-auto">
        <a-button type="primary" ghost shape="round" @click="saveTemporaly()">
          GUARDAR COMO BORRADOR</a-button
        >
        <a-button type="danger" ghost shape="round" @click="closeModal"
          >CANCELAR</a-button
        >
      </div>
      <a-button class="w-2/4 mb-4" type="primary" shape="round" @click="save"
        >REGISTRAR INFORME</a-button
      >
    </Modal>
    <div v-if="feedback">
      <h1 class="text-xl font-bold text-left mx-4 my-4">
        Error finalizando reporte
      </h1>
      <p
        v-for="(feed, index) in feedback"
        :key="index"
        class="bg-red-100 rounded-xl p-4 m-2 text-left capitalize text-gray-800"
      >
        {{ feed.replace(/\[|\]/g, '"') }}
      </p>
    </div>
  </div>
</template>
<script>
import Step1 from "@/components/Dashboard/FCO/Reports/FormSteps/Step1.vue";
import Step2 from "@/components/Dashboard/FCO/Reports/FormSteps/Step2.vue";
import Step3 from "@/components/Dashboard/FCO/Reports/FormSteps/Step3.vue";
import Step4 from "@/components/Dashboard/FCO/Reports/FormSteps/Step4.vue";
import Step5 from "@/components/Dashboard/FCO/Reports/FormSteps/Step5.vue";
export default {
  components: { Step1, Step2, Step3, Step4, Step5 },
  props: {
    company_id: { type: String },
    annual_report: { type: String },
  },
  data() {
    return {
      actualStep: 0,
      finishing: false,
      saveSignal: false,
      showModalEndReport: false,
      optiosProducts: [],
      optionsReasons: [],
      report_date: null,
      report_company: "",
      feedback: null,
      steps: [
        {
          title: "1",
        },
        {
          title: "2",
        },
        {
          title: "3",
        },
        {
          title: "4",
        },
        {
          title: "5",
        },
      ],
    };
  },
  created() {},
  methods: {
    onErrorForm(msg, step) {
      this.actualStep = step;
      this.feedback = []
      this.feedback.push(msg)
    },
    next() {
      this.actualStep++;
    },
    previous() {
      if (this.actualStep === 0)
        this.$router.push({
          name: "reportForm",
          query: { annual_report: this.annual_report },
          params: { lectureMode: true, },
        });
      this.actualStep--;
    },
    endReport() {
      this.finishing = true;
      this.endReportConfirmation();
    },
    closeModal() {
      this.showModalEndReport = false;
    },
    async save() {
      this.finishing = true;
      let { error, data } = await this.$api.setFinishReport(
        this.annual_report,
        {
          annual_report: this.annual_report,
          finished: true,
        }
      );
      if (error) {
        this.showToast(
          "error",
          `No es posible finalizar el reporte: ${error?.message}`
        );
        this.feedback = error?.data;
      } else if (data) {
        this.$router.push({
          name: "endReport",
          query: {
            company_name: this.report_company,
            creation_date: this.report_date,
          },
        });
      }
      this.finished = false;
      this.showModalEndReport = false;
    },
    async endReportConfirmation() {
      let { error, data } = await this.$api.getStepReport(
        this.annual_report,
        0
      );
      if (data) {
        this.report_company = data?.data?.general?.company?.name_company;
        this.report_date = data?.data?.general?.created_at;
        this.showModalEndReport = true;
      }
    },
    saveTemporaly() {
      this.saveSignal = true;
      setTimeout(() => {
        this.saveSignal = false;
      }, 1000);
      this.closeModal();
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
  computed: {
    optionsCompanies: function () {
      return this.$store.state.form?.optionsCompanies?.filter(
        (item) => item?.direction == this.$store.state?.session?.user.direction
      );
    },
  },
  watch: {},
};
</script>
<style>
.btn-save-temporaly {
  background-color: rgb(48, 157, 126);
  border-color: rgb(48, 157, 126);
  position: fixed;
  right: 4em;
  top: 19em;
  z-index: 49;
}

.ant-steps-item-process .ant-steps-item-icon {
  background: #3366cc !important;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #3366cc;
}
</style>
